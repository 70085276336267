import {
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Switch,
} from "@mui/material";
import { editFormData } from "../Apis";
import { useState } from "react";
import {
  formActiveMsg,
  formDeActiveMsg,
  somethingWentWrongMsg,
} from "../utils/constants";

const SideBarForm = ({ item, onEditForm, selectedForm }) => {
  const [isActive, setIsActive] = useState(item.active);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const handleChange = async (e) => {
    setIsActive(e.target.checked);
    try {
      await editFormData({ ...item, active: e.target.checked }, item.id);
      setSnackBarMsg(e.target.checked ? formActiveMsg : formDeActiveMsg);
    } catch (e) {
      setSnackBarMsg(somethingWentWrongMsg);
      setIsActive(!e.target.checked);
    }
  };

  return (
    <>
      <Snackbar
        open={!!snackBarMsg}
        autoHideDuration={3000}
        onClose={() => setSnackBarMsg("")}
        message={snackBarMsg}
      />

      <ListItem
        disablePadding
        key={item.id}
        secondaryAction={
          <Switch
            checked={isActive}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
      >
        <ListItemButton
          onClick={() => onEditForm(item.id)}
          selected={item.id === selectedForm}
        >
          <ListItemText primary={item.title} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default SideBarForm;
