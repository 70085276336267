import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const NewFormConfirmationDialog = (props) => {
  const { onClose, open, onSubmit } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Add New Form</DialogTitle>
      <DialogContent>
        <DialogContentText mb={4}>
          Do you want to create a new form ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          No
        </Button>
        <Button type="submit" variant="contained" onClick={onSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewFormConfirmationDialog;
