import { Box, Button, Snackbar, Typography, useTheme } from "@mui/material";
import { useFormContext } from "./FormContext";
import { addFormData, editFormData } from "./Apis";
import { useState } from "react";
import {
  formSubmitSuccessMsg,
  formUpdateSuccessMsg,
  logoValidMsg,
  somethingWentWrongMsg,
} from "./utils/constants";
import { isValidUrl } from "./utils/helper";

const Header = (props) => {
  const { formFields, setFormFields, selectedForm, setSelectedForm } =
    useFormContext();
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const onReset = () => {
    const idType = formFields.find((item) => !!item && item.type === "id");
    if (idType) {
      let fields = [];
      fields[5] = idType;
      setFormFields(fields);
    } else {
      setFormFields([]);
    }
  };

  const onSubmit = async () => {
    let fields = formFields.filter((field) => !!field);
    let output = {};
    let position = [];

    var editId = -1;
    const idType = formFields.filter((item) => !!item && item.type === "id");
    if (idType.length > 0) {
      editId = idType[0].values.id;
    }

    fields.forEach((field, index) => {
      output[field.type] = { ...field.values, position: index };
      position.push(field.type);
    });

    if (output?.icon?.logoLink && !isValidUrl(output?.icon?.logoLink)) {
      return setSnackBarMsg(logoValidMsg);
    }

    if (Object.keys(output).length && Object.keys(output).includes("title")) {
      const request = {};
      if (output?.title) {
        request.title =
          output?.title?.title || (editId !== -1 ? "" : "Title here");
      }

      if (output?.body) {
        request.body =
          output?.body?.title || (editId !== -1 ? "" : "Subtitle here");
      }
      if (output?.textBox) {
        request.textBox = {
          label: output?.textBox?.label || (editId !== -1 ? "" : "Enter label"),
          fieldName: output?.textBox?.fieldName || "",
        };
      }
      if (output?.buttonText) {
        request.buttonText = {
          label:
            output?.buttonText?.label || (editId !== -1 ? "" : "BUTTON LABEL"),
          deepLink: output?.buttonText?.deepLink || "",
        };
      }
      if (output?.icon) {
        request.icon =
          output?.icon?.logoLink ||
          (editId !== -1
            ? ""
            : "https://creatives.attn.tv/spartan/Logo%3D1_c9cdee98.png");
      }
      request.position = position;

      try {
        var response = null;
        if (editId === -1) {
          response = await addFormData(request);
        } else {
          response = await editFormData(request, editId);
        }
        setSelectedForm(null);
        setFormFields([]);
        const { data } = response;
        if (editId === -1) {
          props.reloadData(data.id);
          setSnackBarMsg(formSubmitSuccessMsg);
        } else {
          props.reloadData();
          setSnackBarMsg(formUpdateSuccessMsg);
        }
      } catch (e) {
        setSnackBarMsg(somethingWentWrongMsg);
      }
    } else {
      alert("Select atleast title field to submit.");
    }
  };

  const theme = useTheme();

  return (
    <>
      <Snackbar
        open={!!snackBarMsg}
        autoHideDuration={3000}
        onClose={() => setSnackBarMsg("")}
        message={snackBarMsg}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        p={6}
        borderBottom="1px solid"
        borderColor={theme.palette.grey[300]}
      >
        <Typography variant="h4">Form Builder</Typography>
        <Box display="flex" gap="24px">
          <Box>
            <Button
              variant="outlined"
              onClick={onReset}
              disabled={!selectedForm}
            >
              Reset
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={!selectedForm}
            >
              Publish
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
