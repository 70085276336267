import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { useRef, useState } from "react";
import SettingsContent from "./SettingsContent";
import { useFormContext } from "./FormContext";
import { useDrag, useDrop } from "react-dnd";

const FormField = ({ formField, moveCard, index }) => {
  const { setFormFields } = useFormContext();
  const [showButtons, setShowButtons] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleShowButtons = () => setShowButtons((old) => !old);

  const handleDrawer = () => setOpenDrawer((old) => !old);

  const { type, values } = formField || {};

  const getFormField = () => {
    const { label, ...rest } = values || {};
    let fields = {
      title: {
        component: (
          <Typography
            variant="h3"
            sx={{ overflowWrap: "anywhere" }}
            textAlign="center"
          >
            {values?.title || "Title here"}
          </Typography>
        ),
      },
      body: {
        component: (
          <Typography
            variant="h6"
            sx={{ overflowWrap: "anywhere" }}
            textAlign="center"
          >
            {values?.title || "Subtitle here"}
          </Typography>
        ),
      },
      icon: {
        component: (
          <Avatar
            sx={{ height: 54, width: 54 }}
            src={values?.logoLink}
            alt="icon"
          />
        ),
      },
      textBox: {
        component: (
          <Box width={1}>
            <label style={{ marginBottom: 8 }}>
              {rest.fieldName || "Field Name"}
            </label>
            <TextField
              size="small"
              fullWidth
              {...rest}
              label={label || "Enter Label"}
              inputProps={{ readOnly: true }}
            />
          </Box>
        ),
      },
      buttonText: {
        component: (
          <Button fullWidth variant="contained">
            {values?.label || "Button Label"}
          </Button>
        ),
      },
    };
    return fields[type].component;
  };

  const deleteField = () => {
    setFormFields((old) =>
      old.map((field) => (field?.type === formField?.type ? undefined : field))
    );
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { ...formField, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <>
      <Box
        onMouseEnter={handleShowButtons}
        onMouseLeave={handleShowButtons}
        width="100%"
        display="flex"
        justifyContent="center"
        sx={{ wordWrap: "break-word" }}
        position="relative"
        ref={ref}
        data-handler-id={handlerId}
        opacity={isDragging ? 0 : 1}
      >
        <Box width="350px" display="flex" justifyContent="center">
          {getFormField()}
        </Box>
        {showButtons && (
          <Box position="absolute" right="0px" top="0px">
            <IconButton size="small" onClick={handleDrawer}>
              <SettingsIcon size="small" />
            </IconButton>
            <IconButton size="small" onClick={deleteField}>
              <DeleteIcon size="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Drawer anchor="right" open={openDrawer} onClose={handleDrawer}>
        <SettingsContent formField={formField} />
      </Drawer>
    </>
  );
};

export default FormField;
