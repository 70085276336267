import "./App.css";
import FormBuilder from "./FormBuilder";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./@core/context/settingsContext";
import ThemeComponent from "./@core/theme/ThemeComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <ThemeComponent settings={settings}>
              <div>
                <DndProvider backend={HTML5Backend}>
                  <FormBuilder />
                </DndProvider>
              </div>
            </ThemeComponent>
          );
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
}

export default App;
