import { Box, Typography, IconButton, Card } from "@mui/material";
import FormField from "./FormField";
import { useFormContext } from "./FormContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteFormData } from "./Apis";
import { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";

const FormPreview = (props) => {
  const { formFields, setFormFields } = useFormContext();
  const [editedForm, setEditedForm] = useState();

  const [, drop] = useDrop(() => ({
    accept: "item",
    drop: () => ({ dropped: true }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  useEffect(() => {
    if (formFields) {
      const idType = formFields.filter((item) => !!item && item.type === "id");
      if (idType.length > 0) {
        setEditedForm(idType[0].values.id);
      } else {
        setEditedForm();
      }
    }
  }, [formFields]);

  const deleteForm = async () => {
    const id = formFields.filter((item) => !!item && item.type === "id")[0]
      .values.id;
    if (id) {
      try {
        const response = await deleteFormData(id);
        const { data } = response;
        if (data.data) {
          props.reloadFormsAfterDelete();
        }
      } catch (e) {}
    }
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setFormFields((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  return (
    <Box>
      <Box
        width={"550px"}
        display={"flex"}
        flexDirection={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Form Preview</Typography>
        {editedForm && (
          <IconButton size="small" onClick={deleteForm}>
            <DeleteIcon size="small" />
          </IconButton>
        )}
      </Box>
      <Card
        ref={drop}
        sx={{
          minHeight: 300,
          padding: "24px 24px",
          width: 550,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignItems: "center",
          marginTop: 4,
          marginRight: "32px",
        }}
      >
        {formFields.length ? (
          formFields
            .filter((field) => !!field && field.type !== "id")
            .map((formField, index) => (
              <FormField
                formField={formField}
                key={formField?.type}
                moveCard={moveCard}
                index={index}
              />
            ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flex={1}
          >
            No fields added
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default FormPreview;
