import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  validateStatus: (status) => {
    return status === 200 || status === 304;
  },
});

export { instance as axios };
