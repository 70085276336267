// ** MUI Theme Provider
import { deepmerge } from "@mui/utils";

// ** Theme Override Imports
import palette from "./palette";
import spacing from "./spacing";
import shadows from "./shadows";
import overrides from "./overrides";
import typography from "./typography";
import breakpoints from "./breakpoints";

const themeOptions = (settings, overrideMode) => {
  // ** Vars
  const { skin, mode, direction, themeColor } = settings;

  const mergedThemeConfig = deepmerge({
    breakpoints: breakpoints(),
    direction,
    components: overrides(settings),
    palette: palette(mode === "semi-dark" ? overrideMode : mode, skin),
    ...spacing,
    shape: {
      borderRadius: 10,
    },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
    },
    shadows: shadows(mode === "semi-dark" ? overrideMode : mode),
    typography,
  });

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...(mergedThemeConfig.palette
          ? mergedThemeConfig.palette[themeColor]
          : palette(mode === "semi-dark" ? overrideMode : mode, skin).primary),
      },
    },
  });
};

export default themeOptions;
