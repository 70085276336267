import { Box, IconButton, Typography } from "@mui/material";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import Header from "./Header";
import FormPreview from "./FormPreview";
import { FormContext } from "./FormContext";
import { getFormData } from "./Apis";
import AddBoxIcon from "@mui/icons-material/AddBox";
import NewFormConfirmationDialog from "./NewFormConfirmationDialog";
import SideBarForms from "./SidebarForms";

const FormBuilder = () => {
  const [formFields, setFormFields] = useState([]);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [addNewForm, setAddNewForm] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const handleAddNewForm = () => {
    setAddNewForm((old) => !old);
  };

  const reload = async (formID) => {
    await getData();
    setFormFields([]);
    /*if(!formID) {
      setFormFields([]);
    } else {
      loadForm(formID)
    }*/
  };

  const getData = async () => {
    try {
      const response = await getFormData();
      const { data } = response;
      if (data) {
        setForms(data);
        setSelectedForm(null);
        setRefresh(!refresh);
      }
    } catch (e) {}
  };

  const loadForm = (id) => {
    const data = forms.find((item) => !!item && item.id === id);
    if (!data) return;
    setSelectedForm(id);
    let fields = [];

    JSON.parse(data.position).forEach((item) => {
      if (item === "title") {
        fields.push({
          type: "title",
          values: { title: data.title },
        });
      }
      if (item === "icon") {
        fields.push({
          type: "icon",
          values: { logoLink: data.icon },
        });
      }
      if (item === "body") {
        fields.push({
          type: "body",
          values: { title: data.body },
        });
      }
      if (item === "textBox") {
        fields.push({
          type: "textBox",
          values: { ...data.textBox },
        });
      }
      if (item === "buttonText") {
        fields.push({
          type: "buttonText",
          values: { ...data.buttonText },
        });
      }
    });
    fields.push({ type: "id", values: { id } });
    setFormFields(fields);
  };

  const onClickAddForm = () => {
    if (selectedForm && selectedForm !== "new") {
      handleAddNewForm();
    } else {
      setSelectedForm("new");
      setFormFields([]);
    }
  };

  const onAddForm = () => {
    setSelectedForm("new");
    setFormFields([]);
    handleAddNewForm();
  };

  const value = {
    formFields,
    setFormFields,
    selectedForm,
    setSelectedForm,
  };

  return (
    <>
      <FormContext.Provider value={value}>
        <Box height="100vh" width={1} display="flex" flexDirection="column">
          <Header reloadData={(reloadFormId) => reload(reloadFormId)} />
          <Box flex={1} display="flex">
            <SideBarForms
              selectedForm={selectedForm}
              forms={forms}
              loadForm={(id) => loadForm(id)}
              onCreateNewForm={onClickAddForm}
            />
            {selectedForm ? (
              <>
                <SideBar />
                <Box flex={1} display="flex" justifyContent="center" mt={6}>
                  <FormPreview reloadFormsAfterDelete={() => reload()} />
                </Box>
              </>
            ) : (
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <IconButton onClick={onClickAddForm}>
                  <AddBoxIcon sx={{ fontSize: 60 }} color="primary" />
                </IconButton>
                <Typography variant="h6">
                  Select any form or create new form to see the form preview.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </FormContext.Provider>
      {addNewForm && (
        <NewFormConfirmationDialog
          open={addNewForm}
          onClose={handleAddNewForm}
          onSubmit={onAddForm}
        />
      )}
    </>
  );
};

export default FormBuilder;
