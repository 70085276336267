import { axios } from "../../Axios";

export const getFormData = async () => {
  return await axios.get("/form/all");
};

export const addFormData = async (data) => {
  return await axios.post("/form/add", data);
};

export const deleteFormData = async (id) => {
  return await axios.delete("/form/delete/" + id);
};

export const editFormData = async (data, id) => {
  return await axios.put("/form/update/" + id, data);
};
