import { Box, Button } from "@mui/material";
import { useDrag } from "react-dnd";

const SidebarOption = ({ option, onAddFormField }) => {
  const [, drag] = useDrag(() => ({
    type: "item",
    item: option,
    end: (item, monitor) => {
      //   const dropResult = monitor.getDropResult();
      if (item) {
        onAddFormField(item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Box ref={drag}>
      <Button variant="contained" fullWidth onClick={(e) => e.preventDefault()}>
        {option.name}
      </Button>
    </Box>
  );
};

export default SidebarOption;
