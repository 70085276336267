import { Box, useTheme } from "@mui/material";
import { formOptions } from "./utils/formOptions";
import { useFormContext } from "./FormContext";
import FormLogoDialog from "./FormLogoDialog";
import { useState } from "react";
import SidebarOption from "./SideBarOption";

const SideBar = () => {
  const { setFormFields } = useFormContext();
  const [openFormLogoDialog, setFormLogoDialog] = useState(false);
  const theme = useTheme();

  const handleFormLogoDialog = () => setFormLogoDialog((old) => !old);

  const onAddLogoLink = (link) => {
    setFormFields((old) => {
      let updated = [...old];
      let index = updated.findIndex((field) => field?.type === "icon");
      let logoItem = { type: "icon", values: { logoLink: link } };
      if (index > -1) {
        updated[index] = logoItem;
      } else {
        updated.push(logoItem);
      }
      return updated;
    });
    handleFormLogoDialog();
  };

  const onAddFormField = (field) => {
    const { type, position, name, id, ...rest } = field;
    if (type === "icon") {
      return handleFormLogoDialog();
    }
    let isPresent = false;
    setFormFields((old) => {
      isPresent = !!old.find((field) => field?.type === type);
      if (isPresent) return old;
      return [...old, { type, values: { ...rest } }];
    });
    if (isPresent) return alert("field is already present in the form");
  };

  return (
    <>
      <Box
        height={1}
        width="250px"
        p={6}
        borderRight="1px solid"
        borderColor={theme.palette.grey[300]}
      >
        <Box mt={10} display="flex" flexDirection="column" gap={4}>
          {formOptions.map((option) => (
            <SidebarOption
              key={option.id}
              option={option}
              onAddFormField={onAddFormField}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SideBar;
