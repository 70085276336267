import { Box, Button, List, Typography, useTheme } from "@mui/material";
import SideBarForm from "./SideBarForm";

const SideBarForms = (props) => {
  const { forms, selectedForm, onCreateNewForm } = props;
  const theme = useTheme();

  const onEditForm = (id) => {
    props.loadForm(id);
  };

  return (
    <Box
      height={1}
      width="250px"
      p={6}
      borderRight="1px solid"
      borderColor={theme.palette.grey[300]}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Forms</Typography>
        <Button size="small" variant="contained" onClick={onCreateNewForm}>
          Add New
        </Button>
      </Box>
      <List>
        {forms.length ? (
          forms.map((item) => (
            <SideBarForm
              item={item}
              onEditForm={onEditForm}
              selectedForm={selectedForm}
              key={item.id}
            />
          ))
        ) : (
          <Box>
            <Typography>No forms added.</Typography>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default SideBarForms;
