export const formOptions = [
  { name: "Add Logo", id: 3, type: "icon", position: 0 },
  { name: "Add Title", id: 1, type: "title", position: 1 },
  { name: "Add SubTitle", id: 2, type: "body", position: 2 },
  { name: "Add Input Field", id: 4, type: "textBox", position: 3 },
  {
    name: "Add  Button",
    id: 5,
    type: "buttonText",
    position: 4,
    deepLink: "https://api.iterable.com/api/users/update",
  },
];
